<template>
    <div class="row" >     
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title" >Ticket Invoices</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                    <li class="breadcrumb-item active">Ticket Invoices</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a  v-on:click="GotoPage('/AddTicket')" href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    Add New
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);" class="btn btn-sm btn-outline-danger">
                                    Close
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-8" style="padding-top:20px">
                    <div class="input-group">
                        <button class="btn btn-secondary" type="button" id="button-addon1"><i class="fas fa-search"></i></button>
                        <input v-model="search" type="text" class="form-control" :placeholder="Search" aria-label="Example text with button addon" aria-describedby="button-addon1">
                    </div>
                </div>
                        
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th >#</th>
                                   
                                    <th  class="text-center">
                                        Invoice No
                                    </th>
                                    <th class="text-center"  >
                                        Bill Type
                                    </th>

                                    <th class="text-center" >
                                        Billable
                                    </th>
                                    <th class="text-center">
                                       Invoice Date
                                    </th>
                                    <th class="text-center">
                                        Status
                                    </th>
                                    <th class="text-center">
                                        Remarks
                                    </th>
                                    
                                    <th class="text-center">
                                        Payable
                                    </th>
                                    <th class="text-center">
                                        Paid
                                    </th>
                                    <th class="text-center">
                                        Due
                                    </th>
                                    <th class="text-center">
                                        Departure Date
                                    </th>
                                    <th class="text-center">
                                        Arrival Date
                                    </th>
                                    <th class="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(ticket,index) in ticketlist" v-bind:key="ticket.id">
                                    <td v-if="currentPage === 1">
                                        {{index+1}}
                                    </td>
                                    <td v-else>
                                        {{((currentPage*10)-10) +(index+1)}}
                                    </td>
                                    <td class="text-center" >
                                        <strong>
                                            <a href="javascript:void(0)" v-on:click="editTicket(ticket.id)">{{ticket.invoiceNo}}</a>
                                        </strong>
                                    </td>
                                    <td class="text-center">{{ticket.billType}}</td>

                                    <td class="text-center">{{ticket.billToName}}</td>
                                    <td class="text-center">{{ticket.invoiceDateStr}}</td>
                                    <td class="text-center">{{ticket.ticketType}}</td>

                                    <td class="text-center">{{ticket.remarks}}</td>
                                    <td class="text-center">{{ticket.payable}}</td>
                                    <td class="text-center">{{ticket.cashPaid}}</td>
                                    <td class="text-center">{{ticket.due}}</td>

                                    <td class="text-center">{{ticket.departureDateStr}}</td>
                                    <td class="text-center">{{ticket.arrivalDateStr}}</td>
                                    <td class="text-center"></td>
                                    

                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />

                    <div class="float-start">
                        <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-end">
                        <div class="" v-on:click="GetSizeData()">
                            <b-pagination pills size="sm" v-model="currentPage"
                                                    :total-rows="rowCount"
                                                    :per-page="10"
                                                    :first-text="$t('Table.First')"
                                                    :prev-text="$t('Table.Previous')"
                                                    :next-text="$t('Table.Next')"
                                                    :last-text="$t('Table.Last')" >
                                                </b-pagination>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

    </div>

</template>


<script>
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        props: [],
        mixins: [clickMixin],
        data: function () {
            return {
                
                searchQuery: '',
                show: false,
                ticketlist: [],
                
                type: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
            }
        },
        watch: {
            
        },
        methods: {
            

            editTicket:function(id){
                this.$router.push({ path: 'AddTicket',query: { id: id} });
            },
            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            getPage: function () {
                this.GetTicketData(this.search, this.currentPage);
            },
           
            GetTicketData: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
              

                root.$https.get('Sale/GetTicketList?' + '&pageNumber=' + this.currentPage , { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        debugger;
                        root.ticketlist = response.data.results;
                        //root.pageCount = response.data.pageCount;
                        //root.rowCount = response.data.rowCount;
                        root.loading = false;
                    }
                    root.loading = false;
                });
            },
            
        },
        created: function () {
        },
        mounted: function () {
            
            this.GetTicketData();
        }
    }
</script>