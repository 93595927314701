<template>

    <div class="row">
        <!--class="col-sm-12 offset-sm-1"-->
        <div class="col-sm-12 ">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">{{ $t('PrintSetting.InvoiceSetting') }}</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('PrintSetting.Home') }}</a></li>
                                    <li class="breadcrumb-item active">{{ $t('PrintSetting.InvoiceSetting') }}</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);" class="btn btn-sm btn-outline-danger">
                                    {{ $t('color.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row form-group">
                       

                        
                      
                        

                      


                   
                        <div class="form-group has-label col-sm-4 " >
                            <label class="text  font-weight-bolder">   {{$t('PrintSetting.BankAccount')}}: </label>
                            <accountdropdown v-model="printSetting.bankAccountId" :formName="bank" :values="printSetting.bankAccountId" v-bind:key="bankRander"></accountdropdown>
                        </div>
                        <div class="form-group has-label col-sm-4 " >
                            <label class="text  font-weight-bolder">   {{ $t('PrintSetting.CashAccount') }}: </label>
                            <accountdropdown v-model="printSetting.cashAccountId" :formName='cash' :values="printSetting.cashAccountId" v-bind:key="bankRander"></accountdropdown>
                        </div>
                        <div class="form-group has-label col-sm-4 mt-4 " >
                            <div class="checkbox form-check-inline mx-2 " >
                                    <input type="checkbox" id="IsHeaderFooter" v-model="printSetting.isHeaderFooter">
                                    <label for="IsHeaderFooter">Header</label>
                                </div>
                        </div>


                        <div class="accordion" id="accordionExample">
                           
                            <div class="accordion-item">
                                <h5 class="accordion-header m-0" id="headingTwo">
                                    <button class="accordion-button collapsed fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        {{ $t('PrintSetting.TermAndCondition') }}
                                    </button>
                                </h5>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <p class="text-white font-weight-bold" style="background: #3178f6; padding: 5px;">   {{ $t('PrintSetting.TermsInEnglish') |englishLanguage }}: </p>
                                                <textarea class="form-control" rows="3" v-model="printSetting.termsInEng"></textarea>
                                            </div>

                                            <div class="col-sm-12 mt-3">
                                                <p class="text-white font-weight-bold" style="background: #3178f6; padding: 5px;"> {{ $t('PrintSetting.TermsInArabic') |arabicLanguage}}   : </p>
                                                <textarea class="form-control" rows="3" v-model="printSetting.termsInAr"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>

                   
                </div>
                <div class="card-footer" v-if="printSetting.id=='00000000-0000-0000-0000-000000000000'">

                    <button type="button" class="btn btn-soft-primary me-2" v-on:click="SaveprintSetting" v-bind:disabled="$v.printSetting.$invalid"> {{ $t('PrintSetting.btnSave') }}</button>
                    <button type="button" class="btn btn-soft-danger   " v-on:click="close()">{{ $t('PrintSetting.btnClear') }}</button>

                </div>
                <div class="card-footer" v-else>

                    <button type="button" class="btn btn-soft-primary me-2" v-on:click="SaveprintSetting" v-bind:disabled="$v.printSetting.$invalid"> {{ $t('PrintSetting.btnUpdate') }}</button>
                    <button type="button" class="btn btn-soft-danger " v-on:click="close()">{{ $t('PrintSetting.btnClear') }}</button>

                </div>
                <div class="card-footer" >

                    <button type="button" class="btn btn-soft-primary me-2" v-on:click="UpdateInventories" > Update Inventories</button>

                </div>
            </div>

        </div>
        <SaleInvoiceView :printDetails="printDetails" :show="show" :headerFooter="headerFooter" :printTemplate="printTemplate" :printSize="printSize" :isTouchScreen="sale" v-if="show " @close="show=false" v-bind:key="printRender"></SaleInvoiceView>
    </div>


</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import 'vue-loading-overlay/dist/vue-loading.css';
    // import Multiselect from 'vue-multiselect'
    // import { VueEditor } from "vue2-editor";
    import { required } from "vuelidate/lib/validators"
    export default {
        mixins: [clickMixin],
        components: {
           // Multiselect
            // VueEditor
        },
        data: function () {
            return {
                pDeleteholtSetupShow: false,
                holdSetup1: {
                    Id: '',
                    holdRecordType: '',
                    isActive: true,
                },
                holtSetupShow: false,
                holdSetup: {
                    Id: '',
                    holdRecordType: '',
                    isActive: true,
                },

                language: 'Nothing',
                renderPrinter: 0,
                printSetting: {
                    id: '00000000-0000-0000-0000-000000000000',
                    returnDays: 0,
                    exchangeDays: 0,
                    printTemplate: '',
                    printSize: '',
                    termsInEng: '',
                    termsInAr: '',
                    cashAccountId: '',
                    bankAccountId: '',
                    bankAccount1: '',
                    bankAccount2: '',
                    welcomeLineEn: '',
                    welcomeLineAr: '',
                    closingLineEn: '',
                    closingLineAr: '',
                    bankIcon1: '',
                    bank1Id: '',
                    bankIcon2: '',
                    bank2Id: '',
                    contactNo: '',
                    managementNo: '',
                    isActive: true,
                    printerName: '',
                    businessAddressEnglish: '',
                    businessAddressArabic: '',
                    walkInInvoiceType: 'B2B',
                    isHeaderFooter: false,
                    englishName: false,
                    arabicName: false,
                    customerNameEn: false,
                    customerNameAr: false,
                    customerAddress: false,
                    customerVat: false,
                    customerNumber: false,
                    isQuotationPrint: false,
                    cargoName: false,
                    customerTelephone: false,
                    itemPieceSize: false,
                    styleNo: false,
                    blindPrint: false,
                    showBankAccount: false,
                    invoicePrint: '',
                    isBlindPrint: false,
                    autoPaymentVoucher: false,
                    isDeliveryNote: false,
                    termAndConditionLength: true,
                    printOptions: [],
                    walkInInvoiceTypeOptions: [],

                    withSubTotal: false,
                    continueWithPage: false,
                    subTotalWithDashes: false,
                    discountTypeOption: '',
                    taxMethod: '',
                    taxRateId: '',
                },
                test: false,
                sale: false,
                show: false,
                image: '',
                image2: '',
                arabic: '',
                printTemplate: '',
                filePath: null,
                english: '',
                render: 0,
                renderedImage: 0,
                renderedImage2: 0,
                randerToggle: 0,
                bankRander: 0,
                randerBanks: 0,
                cash: 'CashReceipt',
                bank: 'BankReceipt',
                isDayStart: '',
                printTemplatelist: [],
                englishOptions: [],
                optionsPageSize: [],
                printDetails: [],
                templateRender: 0,
                printRender: 0,
                headerFooter: {
                    footerEn: '',
                    footerAr: '',
                    company: ''
                },

                storeContact: false,
                managementNo: false,
                welcomeEn: false,
                welcomeAr: false,
                gratitudeMessageAr: false,
                gratitudeMessageEn: false,
                termEn: false,
                termAr: false,
                businessAddressEn: false,
                businessAddressAr: false,
            }
        },
       
        validations: {
            printSetting: {
                cashAccountId: {
                    required,
                },
                bankAccountId: {
                    required
                },
            }
        },
        methods: {
            
            
           
            
           
           
            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            GetBank1Account: function (account) {

                this.printSetting.bank1Id = account.id;
                this.printSetting.bankAccount1 = account.accountNumber;
            },
            GetBank2Account: function (account) {

                this.printSetting.bank2Id = account.id;
                this.printSetting.bankAccount2 = account.accountNumber;
            },
            
            
         
          
            close: function () {
                this.$router.push('/StartScreen');
            },
           
            UpdateInventories: function () {

                var token = '';



                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
               

                this.$https.get('/Sale/UpdateInventories', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    alert('success')
                    console.log(response)
                });
            },
            SaveprintSetting: function () {

                var root = this;
                var token = '';



                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                localStorage.setItem('IsHeaderFooter', this.printSetting.isHeaderFooter);
                localStorage.setItem('CashAccountId', this.printSetting.cashAccountId);
                localStorage.setItem('BankAccountId', this.printSetting.bankAccountId);

                this.$https.post('/Sale/SaveprintSetting', this.printSetting, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess == true) {

                        
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully!' : '!حفظ بنجاح',
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });


                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: "Your printSetting Name  Already Exist!",
                            type: 'error',
                            icon: 'error',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                });
            },
            
            EditprintSetting: function () {
                this.optionsPageSize = []
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                


                root.$https.get('/Sale/printSettingDetail', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {

                            localStorage.setItem('CashAccountId', response.data.cashAccountId);
                            localStorage.setItem('BankAccountId', response.data.bankAccountId);

                            root.printSetting.id = response.data.id;
                            root.printSetting.isHeaderFooter = response.data.isHeaderFooter;
                            root.printSetting.bankAccountId = response.data.bankAccountId;
                            root.printSetting.cashAccountId = response.data.cashAccountId;


                            
                            root.printSetting.withSubTotal = response.data.withSubTotal;
                            root.printSetting.discountTypeOption = response.data.discountTypeOption;
                            root.printSetting.taxMethod = response.data.taxMethod;
                            root.printSetting.taxRateId = response.data.taxRateId;




                            root.render++;
                            root.bankRander++;
                            root.randerBanks++;
                            root.renderedImage++;
                            root.renderedImage2++;
                            root.randerToggle++;

                        } else {
                            console.log("error: something wrong from db.");
                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });

            },
            
        },
        created() {
            this.$emit('input', this.$route.name);

            this.walkInInvoiceTypeOptions = ['B2B', 'B2C']
            this.EditprintSetting();

        },
        mounted: function () {

            var getLocale = this.$i18n.locale;
            this.language = getLocale;
            this.english = 'true';
            this.arabic = localStorage.getItem('Arabic');


        }
    }
</script>