import Vue from 'vue'
import VueRouter from 'vue-router'
//import i18n from '../i18n'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import NewPassword from '../views/NewPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Navbar from '../views/Navbar.vue'
import CommingSoon from '../views/CommingSoon.vue'
//import App from '../App.vue'
import RegisterUser from '../components/RegisterUser.vue'
import SetupUser from '../components/RegisterUser.vue'
import AddCompany from '../components/Company/AddCompany.vue'
import CompanyInfo from '../components/CompanyProfile/CompanyInfo.vue'
import CompanyProfile from '../components/CompanyProfile/CompanyProfile.vue'
import Permission from '../components/CompanyProfile/Permission.vue'
import Company from '../components/Company/Company.vue'
import ClientManagement from '../components/Company/ClientManagement.vue'
/*import SuperduperAdmin from '../components/Dashboard/SuperduperAdmin.vue'*/

import AddSupplier from '../components/Supplier/AddSupplier.vue'
import Supplier from '../components/Supplier/Supplier.vue'
import AddCustomer from '../components/Customer/AddCustomer.vue'
import Customer from '../components/Customer/Customer.vue'

import AddProduct from '../components/Product/AddProduct.vue'
import Ticket from '../components/Ticket/Ticket.vue'
import AddTicket from '../components/Ticket/AddTicket.vue'
import AddWarranty from '../components/Warranty/AddWarranty.vue'
import Warranty from '../components/Warranty/Warranty.vue'
import Product from '../components/Product/Product.vue'
import Category from '../components/Category/Category.vue'
import SubCategory from '../components/SubCategory/SubCategory.vue'
import AddWarehouse from '../components/Warehouse/AddWarehouse.vue'
import Warehouse from '../components/Warehouse/Warehouse.vue'
import Brand from '../components/Brand/Brand.vue'
import Unit from '../components/Unit/Unit.vue'
import Color from '../components/Color/Color.vue'
import Size from '../components/Size/Size.vue'
import Origin from '../components/Origin/Origin.vue'
import CaseUser from '../components/CaseUser.vue'
import TaxRate from '../components/TaxRate/TaxRate.vue'
import AddContact from '../components/Color/AddContact.vue'

import HolidaySetup from '../components/Hr/ShiftManagement/HolidaySetup.vue';

import coa from '../components/COA/AddUpdateAccount.vue'
import Setupcoa from '../components/COA/AddUpdateAccount.vue'
import VueSession from 'vue-session'
//PurchaseOrder
import AddPurchase from '../components/Purchase/AddPurchase.vue'
import AddCurrency from '../components/Currency/AddCurrency.vue'
import Currency from '../components/Currency/Currency.vue'

import Purchase from '../components/Purchase/Purchase.vue'
import AddPurchaseOrder from '../components/PurchaseOrder/AddPurchaseOrder.vue'
import PurchaseOrder from '../components/PurchaseOrder/PurchaseOrder.vue'
import EmployeeRegistration from '../components/EmployeeRegistration/EmployeeRegistration.vue'
import EmployeeView from '../components/EmployeeRegistration/EmployeeView.vue'
import AddEmployeeRegistration from '../components/EmployeeRegistration/AddEmployeeRegistration.vue'
import SignUp from '../components/LoginCredentials/SignUp.vue'
import AddSignUp from '../components/LoginCredentials/AddSignUp.vue'
import Register from '../components/LoginCredentials/SignUpCaseUser.vue'
import PaymentGateWay from '../components/PaymentGateWay/PaymentGateWay.vue'

//Sale

//Sale Service
import AddSaleService from '../components/SaleServiceInvoice/AddSaleService.vue'
import SaleService from '../components/SaleServiceInvoice/SaleService.vue'
import TestReport from '../components/SaleServiceInvoice/TestReport.vue'
import TouchScreen from '../components/TouchScreen/TouchScreen.vue'

import PurchaseReturn from '../components/PurchaseReturn/PurchaseReturn.vue'
import AddPurchaseReturn from '../components/PurchaseReturn/AddPurchaseReturn.vue'


//JournalVoucher
import AddJournalVoucher from '../components/JournalVouchers/AddJournalVoucher.vue'
import JournalVoucherView from '../components/JournalVouchers/JournalVoucherView.vue'

//InvoiceReport
import InvoiceReport from '../components/Reports/InvoiceReport.vue'
import DailyExpenseReport from '../components/Reports/DailyExpenseReport.vue'




//PaymentVoucher
import AddPaymentVoucher from '../components/PaymentVouchers/AddPaymentVoucher.vue'
import PaymentVoucherList from '../components/PaymentVouchers/PaymentVoucherList.vue'


//JournalVoucher
import AddDailyExpense from '../components/DailyExpense/AddDailyExpense.vue'
import DailyExpense from '../components/DailyExpense/DailyExpense.vue'


//Sale Return
import AddSaleReturn from '../components/SaleReturn/AddSaleReturn.vue'
import SaleReturn from '../components/SaleReturn/SaleReturn.vue'
import ViewSaleReturn from '../components/SaleReturn/ViewSaleReturn.vue'



//Reporting


//

import Bank from '../components/Bank/Bank.vue'
import AddBank from '../components/Bank/AddBank.vue'






Vue.use(VueSession);
Vue.use(VueRouter);


//NobleRoles
import AddRoles from '../components/UserRoles/AddRoles.vue'
import Roles from '../components/UserRoles/Roles.vue'



//Import Categorey
//Import Product
import ImportProduct from '../components/Imports/ImportProduct.vue'


// Terms And Conditions
import TermsAndConditions from '../components/TermsAndConditions/TermAndCondition.vue'
import Setup from '../components/TermsAndConditions/Setup.vue'


//Term Dashboard
import TermDashboard from '../components/Dashboard/TermsDashboard.vue'

// Hr Dashboard
import MainDashboard from '../components/Dashboard/Dashboard.vue'

//Product Management
//import ProductManagement from '../components/SidebarMenu/ProductManagement.vue'
import UserSetup from '../components/SidebarMenu/UserSetup.vue'
//import HrSetup from '../components/SidebarMenu/HrSetup.vue'

import StartScreen from '../components/UserScreen/StartScreen.vue'
import Welcome from '../components/UserScreen/Welcome.vue'


//Print Setting
import PrintSetting from '../components/PrintSetting/PrintSetting.vue'


// COA Template
import CoaTemplate from '../components/COA/CoaTemplate.vue'

import PaymentVoucherView from '../components/PaymentVouchers/PaymentVoucherView.vue'


import ProductMaster from '../components/ProductMaster/ProductMaster.vue'
import ServiceProformaInvoice from '../components/ProformaInvoice/ServiceProformaInvoice.vue'


import FinancialYear from '../components/FinancialYear/FinancialYear.vue'
import FinancialYearSetup from '../components/FinancialYear/FinancialYearSetup.vue'


/*Sale Service Order*/
import AddSaleServiceOrder from '../components/SaleServiceOrder/AddSaleServiceOrder.vue'
import SaleServiceOrder from '../components/SaleServiceOrder/SaleServiceOrder.vue'
import SaleServiceOrderView from '../components/SaleServiceOrder/SaleServiceOrderView.vue'
//Designation
import DesignForAuthority from '../components/Department/DesignForAuthority.vue'
import Department from '../components/Hr/Employee/Department/Department.vue'
import Designation from '../components/Hr/Employee/Designation/Designation.vue'



//Designation
import City from '../components/City/City.vue'

//Hr
import Leave from '../components/Hr/LeaveManagement/Leave.vue'
import Shift from '../components/Hr/Shift/Shift.vue'
import Deduction from '../components/Hr/Payroll/Deduction/Deduction.vue'
import Contribution from '../components/Hr/Payroll/Contribution/Contribution.vue'
import Allowance from '../components/Hr/Payroll/Allowance/Allowance.vue'
import AllowanceType from '../components/Hr/Payroll/AllowanceType/AllowanceType.vue'
import SalaryTemplate from '../components/Hr/Payroll/SalaryTemplate/SalaryTemplate.vue'
import AddSalaryTemplate from '../components/Hr/Payroll/SalaryTemplate/AddSalaryTemplate.vue'

import AddEmployeeSalary from '../components/Hr/Payroll/EmployeeSalary/AddEmployeeSalary.vue'
import EmployeeSalary from '../components/Hr/Payroll/EmployeeSalary/EmployeeSalary.vue'
import PayrollSchedule from '../components/Hr/Payroll/PayrollSchedule/PayrollSchedule.vue'
import SalaryTaxSlab from '../components/Hr/Payroll/SalaryTaxSlab/SalaryTaxSlab.vue'
import AddSalaryTaxSlab from '../components/Hr/Payroll/SalaryTaxSlab/AddSalaryTaxSlab.vue'

import AddRunPayroll from '../components/Hr/Payroll/RunPayroll/AddRunPayroll.vue'
import RunPayroll from '../components/Hr/Payroll/RunPayroll/RunPayroll.vue'


//Loan Payment
import LoanPayment from '../components/Hr/Payroll/LoanPayment/LoanPayment.vue'
import AddLoanPayment from '../components/Hr/Payroll/LoanPayment/AddLoanPayment.vue'


//Shift Assign
import ShiftAssign from '../components/Hr/ShiftAssign/ShiftAssign.vue'
import AddShiftAssign from '../components/Hr/ShiftAssign/AddShiftAssign.vue'




import ManualAttendance from '../components/ManualAttendance/ManualAttendance.vue'
import AddHolidayOfMonth from '../components/ManualAttendance/AddHolidayOfMonth.vue'
import AttendanceReport from '../components/ManualAttendance/AttendanceReport.vue'
import AttendanceReport2 from '../components/ManualAttendance/AttendanceReport2.vue'
import EmployeeHourOfAttendence from '../components/ManualAttendance/EmployeeHourOfAttendence.vue'
import EmployeeTodayAttendence from '../components/ManualAttendance/EmployeeTodayAttendence.vue'
import ProductInventoryReport from '../components/Reports/ProductInventoryReport.vue'
import StockReport  from '../components/Reports/StockReport.vue'
import StockReportPrint  from '../components/Reports/StockReportPrint.vue'
import StockReportPrintDownload  from '../components/Reports/StockReportPrintDownload.vue'


//Customer Ledger Report
import CustomerLedgerReport from '../components/Reports/CustomerLedgerReport/CustomerLedgerReport.vue'
import OneDayReport from '../components/Reports/OneDayReport.vue'
import CustomerLedgerPrintReport from '../components/Reports/CustomerLedgerReport/CustomerLedgerPrintReport.vue'
import CustomerLedgerDownloadReport from '../components/Reports/CustomerLedgerReport/CustomerLedgerDownloadReport.vue'

import clickMixin from '@/Mixins/clickMixin'
Vue.use(clickMixin);

Vue.use(VueSession);
Vue.use(VueRouter);

const routes = [
    {
        path: '/',

        component:
        {
            render(c) { return c('router-view') },
            
        },
        children:
            [
                
                
                {


                    path: '/TermDashboard',
                    name: 'TermDashboard',
                    component: TermDashboard,
                    children: [
                        {
                            path: '/TermsAndConditions',
                            name: 'TermsAndConditions',
                            component: TermsAndConditions

                        },
                        {
                            path: '/Setup',
                            name: 'Setup',
                            component: Setup
                        },
                        {
                            path: '/Setupcoa',
                            name: 'Setupcoa',
                            component: Setupcoa
                        },
                        {
                            path: '/CoaTemplate',
                            name: 'CoaTemplate',
                            component: CoaTemplate
                        },
                        {
                            path: '/CompanyInfo',
                            name: 'CompanyInfo',
                            component: CompanyInfo
                        },
                       
                      
                        {
                            path: '/SetupUser',
                            name: 'SetupUser',
                            component: SetupUser
                        },
                       
                        {
                            path: '/FinancialYearSetup',
                            name: 'FinancialYearSetup',
                            component: FinancialYearSetup
                        }
                    ]
                },
                {
                    path: '/TouchScreen',
                    name: 'TouchScreen',
                    component: TouchScreen,
                    props(route) {
                        return { formName: route.query.formName };
                    },
                },
                {
                    path: '/NewPassword',
                    name: 'NewPassword',
                    component: NewPassword
                },
                
                {
                    path: '/ResetPassword',
                    name: 'ResetPassword',
                    component: ResetPassword
                },
                {
                    path: '/',
                    name: 'Login',
                    component: Login
                },
               
                {
                    path: '/Register',
                    name: 'Register',
                    component: Register
                },
              
                {
                    path: '/Welcome',
                    name: 'Welcome',
                    component: Welcome
                },


                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    children: [
                        {
                            path:'/MainDashboard',
                            name:'MainDashboard',
                            component:MainDashboard,
                        },
                     
                        {
                            path: '/registeruser',
                            name: 'RegisterUser',
                            component: RegisterUser
                        },
                        {
                            path: '/navbar',
                            name: 'Navbar',
                            component: Navbar
                        },
                        {
                            path: '/Company',
                            name: 'Company',
                            component: Company
                        },
                      

                        {
                            path: '/addcompany',
                            name: 'AddCompany',
                            component: AddCompany
                        },
                        {
                            path: '/StockReportPrintDownload',
                            name: 'StockReportPrintDownload',
                            component: StockReportPrintDownload
                        },
                        {
                            path: '/StockReportPrint',
                            name: 'StockReportPrint',
                            component: StockReportPrint
                        },
                        {
                            path: '/StockReport',
                            name: 'StockReport',
                            component: StockReport
                        },

                       
                        {
                            path: '/ProductInventoryReport',
                            name: 'ProductInventoryReport',
                            component: ProductInventoryReport
                        },

                       
                        {
                            path: '/supplier',
                            name: 'Supplier',
                            component: Supplier
                        }
                        ,
                        {
                            path: '/addsupplier',
                            name: 'AddSupplier',
                            component: AddSupplier
                        }
                        ,
                        {
                            path: '/customer',
                            name: 'Customer',
                            component: Customer,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                       
                        {
                            path: '/addCustomer',
                            name: 'AddCustomer',
                            component: AddCustomer
                        },
                       
                      

                        {
                            path: '/CompanyProfile',
                            name: 'CompanyProfile',
                            component: CompanyProfile
                        },

                        {
                            path: '/Permission',
                            name: 'Permission',
                            component: Permission
                        },

                        {
                            path: '/products',
                            name: 'Product',
                            component: Product,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        }
                        ,
                        {
                            path: '/addproduct',
                            name: 'AddProduct',
                            component: AddProduct,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },

                      
                        {
                            path: '/addpurchase',
                            name: 'Addpurchase',
                            component: AddPurchase
                        },
                       
                       

                        {
                            path: '/addCurrency',
                            name: 'AddCurrency',
                            component: AddCurrency
                        },

                       
                        {
                            path: '/addpurchaseorder',
                            name: 'Addpurchaseorder',
                            component: AddPurchaseOrder,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                        {
                            path: '/purchaseorder',
                            name: 'purchaseorder',
                            component: PurchaseOrder,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                        {
                            path: '/EmployeeTodayAttendence',
                            name: 'EmployeeTodayAttendence',
                            component: EmployeeTodayAttendence
                        },
                        {
                            path: '/OneDayReport',
                            name: 'OneDayReport',
                            component: OneDayReport
                        },
                        {
                            path: '/CustomerLedgerReport',
                            name: 'CustomerLedgerReport',
                            component: CustomerLedgerReport,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                        {
                            path: '/CustomerLedgerPrintReport',
                            name: 'CustomerLedgerPrintReport',
                            component: CustomerLedgerPrintReport
                        },
                        {
                            path: '/CustomerLedgerDownloadReport',
                            name: 'CustomerLedgerDownloadReport',
                            component: CustomerLedgerDownloadReport
                        },
                        {
                            path: '/employeeRegistration',
                            name: 'EmployeeRegistration',
                            component: EmployeeRegistration
                        },
                        {
                            path: '/EmployeeView',
                            name: 'EmployeeView',
                            component: EmployeeView
                        },
                        {
                            path: '/addEmployeeRegistration',
                            name: 'AddEmployeeRegistration',
                            component: AddEmployeeRegistration
                        },
                        {
                            path: '/addSignUp',
                            name: 'AddSignUp',
                            component: AddSignUp
                        },
                        

                        {
                            path: '/addPurchaseReturn',
                            name: 'AddPurchaseReturn',
                            component: AddPurchaseReturn
                        },
                        {
                            path: '/PurchaseReturn',
                            name: 'PurchaseReturn',
                            component: PurchaseReturn
                        },
                        {
                            path: '/ClientManagement',
                            name: 'clientManagement',
                            component: ClientManagement
                        },
                        {
                            path: '/InvoiceReport',
                            name: 'InvoiceReport',
                            component: InvoiceReport,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                        {
                            path: '/AddSaleReturn',
                            name: 'AddSaleReturn',
                            component: AddSaleReturn,
                            
                        },
                        {
                            path: '/SaleReturn',
                            name: 'SaleReturn',
                            component: SaleReturn,
                            
                        },
                        {
                            path: '/ViewSaleReturn',
                            name: 'ViewSaleReturn',
                            component: ViewSaleReturn,
                            
                        },
                        {
                            path: '/DailyExpenseReport',
                            name: 'DailyExpenseReport',
                            component: DailyExpenseReport,
                            
                        },
                        {
                            path: '/addjournalvoucher',
                            name: 'AddJournalVoucher',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: AddJournalVoucher
                        },
                        {
                            path: '/journalvoucherview',
                            name: 'JournalVoucherView',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: JournalVoucherView
                        },
                        {
                            path: '/paymentVoucherList',
                            name: 'PaymentVoucherList',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: PaymentVoucherList
                        },
                                       
                        {
                            path: '/addPaymentVoucher',
                            name: 'addPaymentVoucher',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: AddPaymentVoucher
                        },
                        {

                            path: '/PaymentVoucherView',
                            name: 'PaymentVoucherView',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: PaymentVoucherView
                        },
                      
                       
                        {
                            path: '/dailyexpense',
                            name: 'DailyExpense',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: DailyExpense
                        },
                        {
                            path: '/adddailyexpense',
                            name: 'AddDailyExpense',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: AddDailyExpense
                        },
                     
                        {
                            path: '/addwarehouse',
                            name: 'AddWarehouse',
                            component: AddWarehouse
                        },
                        {
                            path: '/warehouse',
                            name: 'Warehouse',
                            component: Warehouse
                        },
                        
                       
                        
                      
                        {
                            path: '/TestReport',
                            name: 'TestReport',
                            component: TestReport
                        },
                      
                        {
                            path: '/AddSaleServiceOrder',
                            name: 'AddSaleServiceOrder',
                            component: AddSaleServiceOrder
                        },
                        {
                            path: '/Ticket',
                            name: 'Ticket',
                            component: Ticket
                        },
                        {
                            path: '/AddTicket',
                            name: 'AddTicket',
                            component: AddTicket
                        },
                        {
                            path: '/AddWarranty',
                            name: 'AddWarranty',
                            component: AddWarranty
                        },
                        {
                            path: '/Warranty',
                            name: 'Warranty',
                            component: Warranty
                        },
                        {
                            path: '/SaleServiceOrder',
                            name: 'SaleServiceOrder',
                            props(route) {
                                return { formName: route.query.formName };
                            },
                            component: SaleServiceOrder
                        },
                        {
                            path: '/City',
                            name: 'City',
                            component: City
                        },
                      
                        {
                            path: '/SaleServiceOrderView',
                            name: 'SaleServiceOrderView',
                            component: SaleServiceOrderView
                        },
                      
                       

                        {
                            path: '/AddBank',
                            name: 'AddBank',
                            component: AddBank
                        },

                      

                      
                    
                     
                        {
                            path: '/AddRoles',
                            name: 'AddRoles',
                            component: AddRoles
                        },

                      
                        {
                            path: '/ImportProduct',
                            name: 'ImportProduct',
                            component: ImportProduct
                        },
                     
                        {
                            path: '/AddSaleService',
                            name: 'AddSaleService',
                            component: AddSaleService,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                        {
                            path: '/SaleService',
                            name: 'SaleService',
                            component: SaleService,
                            props(route) {
                                return { formName: route.query.formName };
                            },
                        },
                       
                       
                       
                    
                        {
                            path: '/ProductMaster',
                            name: 'ProductMaster',
                            component: ProductMaster
                        },
                        {
                            path: '/category',
                            name: 'Category',
                            component: Category
                        },
                      
                       
                        {
                            path: '/subcategory',
                            name: 'SubCategory',
                            component: SubCategory
                        },
                        {
                            path: '/brand',
                            name: 'Brand',
                            component: Brand
                        },
                        {
                            path: '/unit',
                            name: 'Unit',
                            component: Unit
                        },
                        {
                            path: '/color',
                            name: 'Color',
                            component: Color
                        },
                        {
                            path: '/HolidaySetup',
                            name: 'HolidaySetup',
                            component: HolidaySetup
                        },
                        {
                            path: '/ServiceProformaInvoice',
                            name: 'ServiceProformaInvoice',
                            component: ServiceProformaInvoice
                        },
                        {
                            path: '/size',
                            name: 'Size',
                            component: Size,
                            props(route) {
                                return { formName: route.query.formName, fromService: route.query.fromService};
                            },
                        },
                        {
                            path: '/origin',
                            name: 'Origin',
                            component: Origin
                        },
                        {
                            path: '/CaseUser',
                            name: 'CaseUser',
                            component: CaseUser
                        },
                        {
                            path: '/origin',
                            name: 'Origin',
                            component: Origin
                        },
                      
                      
                        {
                            path: '/purchase',
                            name: 'purchase',
                            component: Purchase
                        },
                      
                        
                        {
                            path: '/Roles',
                            name: 'Roles',
                            component: Roles
                        },
                        {
                            path: '/signUp',
                            name: 'SignUp',
                            component: SignUp
                        },
                        {
                            path: '/PaymentGateWay',
                            name: 'PaymentGateWay',
                            component: PaymentGateWay
                        },
                      

                        {
                            path: '/UserSetup',
                            name: 'UserSetup',
                            component: UserSetup,
                            children: [





                            ],
                        },

                      
                        {
                            path: '/currency',
                            name: 'Currency',
                            component: Currency
                        },
                        {
                            path: '/Bank',
                            name: 'Bank',
                            component: Bank
                        },

                        
                        {
                            path: '/taxrate',
                            name: 'TaxRate',
                            component: TaxRate
                        },
                        {
                            path: '/AddContact',
                            name: 'AddContact',
                            component: AddContact
                        },

                        {
                            path: '/FinancialYear',
                            name: 'FinancialYear',
                            component: FinancialYear
                        },
                        {
                            path: '/Deduction',
                            name: 'Deduction',
                            component: Deduction
                        },
                        {
                            path: '/Shift',
                            name: 'Shift',
                            component: Shift
                        },
                        {
                            path: '/Leave',
                            name: 'Leave',
                            component: Leave
                        },
                        {
                            path: '/ShiftAssign',
                            name: 'ShiftAssign',
                            component: ShiftAssign
                        },
                        {
                            path: '/AddShiftAssign',
                            name: 'AddShiftAssign',
                            component: AddShiftAssign
                        },
                        {
                            path: '/Contribution',
                            name: 'Contribution',
                            component: Contribution
                        },
                        {
                            path: '/Allowance',
                            name: 'Allowance',
                            component: Allowance
                        },
                        {
                            path: '/SalaryTemplate',
                            name: 'SalaryTemplate',
                            component: SalaryTemplate
                        },
                        {
                            path: '/AddSalaryTemplate',
                            name: 'AddSalaryTemplate',
                            component: AddSalaryTemplate
                        },
                        {
                            path: '/AllowanceType',
                            name: 'AllowanceType',
                            component: AllowanceType
                        },
                        {
                            path: '/AddEmployeeSalary',
                            name: 'AddEmployeeSalary',
                            component: AddEmployeeSalary
                        },
                        {
                            path: '/EmployeeSalary',
                            name: 'EmployeeSalary',
                            component: EmployeeSalary
                        },
                        {
                            path: '/LoanPayment',
                            name: 'LoanPayment',
                            component: LoanPayment
                        },
                        {
                            path: '/PayrollSchedule',
                            name: 'PayrollSchedule',
                            component: PayrollSchedule
                        },
                        {
                            path: '/AddLoanPayment',
                            name: 'AddLoanPayment',
                            component: AddLoanPayment
                        },
                        {
                            path: '/SalaryTaxSlab',
                            name: 'SalaryTaxSlab',
                            component: SalaryTaxSlab
                        },
                        {
                            path: '/AddSalaryTaxSlab',
                            name: 'AddSalaryTaxSlab',
                            component: AddSalaryTaxSlab
                        },


                      
                        {
                            path: '/coa',
                            name: 'coa',
                            component: coa
                        },
                        {
                            path: '/RunPayroll',
                            name: 'RunPayroll',
                            component: RunPayroll
                        },
                        {
                            path: '/AddRunPayroll',
                            name: 'AddRunPayroll',
                            component: AddRunPayroll
                        },
                      
                     
                       
                        {
                            path: '/StartScreen',
                            name: 'StartScreen',
                            component: StartScreen
                        },
                       
                        
                        
                     
                        
                      
                        {
                            path: '/PrintSetting',
                            name: 'PrintSetting',
                            component: PrintSetting
                        },

                     
                        {
                            path: '/FinancialYear',
                            name: 'FinancialYear',
                            component: FinancialYear
                        },
                       
                      
                       
                        {
                            path: '/Department',
                            name: 'Department',
                            component: Department
                        },
                        {
                            path: '/DesignForAuthority',
                            name: 'DesignForAuthority',
                            component: DesignForAuthority
                        },
                        {
                            path: '/Designation',
                            name: 'Designation',
                            component: Designation
                        },
                       
                       
                        {
                            path: '/ManualAttendance',
                            name: 'ManualAttendance',
                            component: ManualAttendance
                        },
                        {
                            path: '/AddHolidayOfMonth',
                            name: 'AddHolidayOfMonth',
                            component: AddHolidayOfMonth
                        },
                        {
                            path: '/EmployeeHourOfAttendence',
                            name: 'EmployeeHourOfAttendence',
                            component: EmployeeHourOfAttendence
                        },
                        {
                            path: '/AttendanceReport',
                            name: 'AttendanceReport',
                            component: AttendanceReport
                        },
                        {
                            path: '/AttendanceReport2',
                            name: 'AttendanceReport2',
                            component: AttendanceReport2
                        },
                       
                        
                        {
                            path: '/CommingSoon',
                            name: 'CommingSoon',
                            component: CommingSoon
                        },
                       
                       
                       
                    ]
                }
            ]
    }
];
const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
});

export default router


router.beforeEach((to, from, next) => {
    
    // redirect to login page if not logged in and trying to access a restricted page

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '/assets/js/app.js')
    document.head.appendChild(recaptchaScript);
  

    localStorage.setItem('CurrentPath', to.name);
    next();
});