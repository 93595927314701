<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">Warranty</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                                    <li class="breadcrumb-item active">Warranty</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="GotoPage('/AddWarranty')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="align-self-center icon-xs ti-plus"></i>
                                    Add New
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    Close
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg-8" style="padding-top:20px">
                            <div class="input-group">
                                <button class="btn btn-secondary" type="button" id="button-addon1"><i
                                        class="fas fa-search"></i></button>
                                <input v-model="search" type="text" class="form-control" :placeholder="Search"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1">
                            </div>
                        </div>

                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == 'WarrantyCard' }"
                                    v-on:click="makeActive('WarrantyCard')" data-bs-toggle="tab" href="#link6" role="tab"
                                    aria-selected="true">Warranty Card</a></li>
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == 'Submitted' }"
                                    v-on:click="makeActive('Submitted')" data-bs-toggle="tab" href="#link5" role="tab"
                                    aria-selected="false">Submitted</a></li>
                            <li class="nav-item"><a class="nav-link" v-bind:class="{ active: active == 'Finalized' }"
                                    v-on:click="makeActive('Finalised')" data-bs-toggle="tab" href="#link7" role="tab"
                                    aria-selected="false">Finalised</a></li>
                        </ul>
                    </div>
                    <div class="table-responsive" v-if="active == 'WarrantyCard'">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>

                                    <th class="text-center">
                                        Date
                                    </th>
                                    <th class="text-center">
                                        Vendor
                                    </th>

                                    <th class="text-center">
                                        Submission Date
                                    </th>

                                    <th class="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(warranty, index) in warrantylist">
                                    <tr v-bind:key="warranty.id" v-if="!warranty.isSubmitted">
                                        <td v-if="currentPage === 1">
                                            {{ index + 1 }}
                                        </td>
                                        <td v-else>
                                            {{ ((currentPage * 10) - 10) + (index + 1) }}
                                        </td>
                                        <td class="text-center" v-if="!warranty.isSubmitted">
                                            <strong>
                                                <a href="javascript:void(0)"
                                                    v-on:click="editWarranty(warranty.id)">{{ warranty.warrantyDate }}</a>
                                            </strong>
                                        </td>
                                        <td class="text-center" v-else>
                                            {{ warranty.warrantyDate }}
                                        </td>

                                        <td class="text-center">{{ warranty.vendorName }}</td>
                                        <td class="text-center">{{ warranty.warrantySubmissionDate }}</td>


                                        <td class="text-center"></td>



                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" v-if="active == 'Submitted'">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>

                                    <th class="text-center">
                                        Date
                                    </th>
                                    <th class="text-center">
                                        Vendor
                                    </th>

                                    <th class="text-center">
                                        Submission Date
                                    </th>

                                    <th class="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(warranty, index) in submittedlist">
                                    <tr v-bind:key="warranty.id"
                                        v-if="warranty.isSubmitted && warranty.totalCashbackAmount == 0">
                                        <td v-if="currentPage === 1">
                                            {{ index + 1 }}
                                        </td>
                                        <td v-else>
                                            {{ ((currentPage * 10) - 10) + (index + 1) }}
                                        </td>
                                        <td class="text-center" v-if="!warranty.isSubmitted">
                                            <strong>
                                                <a href="javascript:void(0)"
                                                    v-on:click="editWarranty(warranty.id)">{{ warranty.warrantyDate }}</a>
                                            </strong>
                                        </td>
                                        <td class="text-center" v-else>
                                            {{ warranty.warrantyDate }}
                                        </td>

                                        <td class="text-center">{{ warranty.vendorName }}</td>
                                        <td class="text-center">{{ warranty.warrantySubmissionDate }}</td>


                                        <td class="text-center">
                                            <a v-on:click="AddCashBack(warranty.id, '/AddWarranty')"
                                                href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">

                                                CashBack
                                            </a>
                                        </td>



                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="table-responsive" v-if="active == 'Finalised'">
                        <table class="table mb-0">
                            <thead class="thead-light table-hover">
                                <tr>
                                    <th>#</th>

                                    <th class="text-center">
                                        Date
                                    </th>
                                    <th class="text-center">
                                        Vendor
                                    </th>

                                    <th class="text-center">
                                        Submission Date
                                    </th>

                                    <th class="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(warranty, index) in finalizedlist">
                                    <tr v-bind:key="warranty.id"
                                        v-if="warranty.isSubmitted && warranty.totalCashbackAmount > 0">
                                        <td v-if="currentPage === 1">
                                            {{ index + 1 }}
                                        </td>
                                        <td v-else>
                                            {{ ((currentPage * 10) - 10) + (index + 1) }}
                                        </td>
                                        <td class="text-center" v-if="!warranty.isSubmitted">
                                            <strong>
                                                <a href="javascript:void(0)"
                                                    v-on:click="editWarranty(warranty.id)">{{ warranty.warrantyDate }}</a>
                                            </strong>
                                        </td>
                                        <td class="text-center" v-else>
                                            {{ warranty.warrantyDate }}
                                        </td>

                                        <td class="text-center">{{ warranty.vendorName }}</td>
                                        <td class="text-center">{{ warranty.warrantySubmissionDate }}</td>


                                        <td class="text-center"></td>



                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <hr />

                    <div class="float-start">
                        <span v-if="currentPage === 1 && rowCount === 0"> {{ $t('Pagination.ShowingEntries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount < 10"> {{ $t('Pagination.Showing') }} {{ currentPage }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1 && rowCount >= 11"> {{ $t('Pagination.Showing') }}
                            {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === 1"> {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                            $t('Pagination.to') }} {{ currentPage * 10 }} of {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage !== 1 && currentPage !== pageCount"> {{ $t('Pagination.Showing') }}
                            {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                            {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                        <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{ (currentPage * 10) - 9 }}
                            {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }} {{ rowCount }} {{
                                $t('Pagination.entries') }}</span>
                    </div>
                    <div class="float-end">
                        <div class="" v-on:click="GetSizeData()">
                            <b-pagination pills size="sm" v-model="currentPage" :total-rows="rowCount" :per-page="10"
                                :first-text="$t('Table.First')" :prev-text="$t('Table.Previous')"
                                :next-text="$t('Table.Next')" :last-text="$t('Table.Last')">
                            </b-pagination>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</template>


<script>
import clickMixin from '@/Mixins/clickMixin'
export default {
    props: [],
    mixins: [clickMixin],
    data: function () {
        return {
            active: 'WarrantyCard',
            searchQuery: '',
            show: false,
            warrantylist: [],
            submittedlist: [],
            finalizedlist: [],
            type: '',
            search: '',
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            rander: 0
        }
    },
    watch: {

    },
    methods: {

        makeActive: function (active) {
            this.active = active;
            this.rander++;

        },
        editWarranty: function (id) {
            this.$router.push({ path: 'AddWarranty', query: { id: id } });
        },
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },
        AddCashBack: function (id, link) {
            this.$router.push({ path: link, query: { id: id, formName: 'CashBack' } });
        },
        getPage: function () {
            this.GetWarrantyData(this.search, this.currentPage);
        },
        search22: function () {
            this.GetWarrantyData(this.search, this.currentPage);
        },

        GetWarrantyData: function () {

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            root.$https.get('Sale/GetWarrantyList?' + '&pageNumber=' + this.currentPage, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    debugger;
                    root.warrantylist = response.data.results.filter(x => !x.isSubmitted);
                    root.submittedlist = response.data.results.filter(x => x.isSubmitted && x.totalCashbackAmount == 0);
                    root.finalizedlist = response.data.results.filter(x => x.isSubmitted && x.totalCashbackAmount > 0);
                }
                root.loading = false;
            });
        },

    },
    created: function () {
    },
    mounted: function () {

        this.GetWarrantyData();
    }
}
</script>