<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <div class="col">
                    <h4 class="page-title">Warranty</h4>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Home</a></li>
                        <li class="breadcrumb-item active">Warranties</li>
                    </ol>
                </div>
                <div class="col-auto align-self-center">
                    <a v-on:click="GotoPage('/Warranty')" href="javascript:void(0);" class="btn btn-sm btn-outline-danger">
                        Close
                    </a>
                </div>

            </div>
            <hr class="hr-dashed hr-menu mt-0" />

            <div class="row">

                <div class="form-group has-label col-lg-3 ">
                    <label class="text  font-weight-bolder"> Date: </label>

                    <datepicker v-model="warranty.date" :key="render" />

                </div>
                <div class="form-group has-label col-lg-3 ">
                    <label class="text  font-weight-bolder"> Batch: </label>

                    <multiselect v-model="batch.batch" v-on:input="onSelectBatch(batch.batch)" :options="batch.variantList" :multiple="false"
                                                :value="batch.variantList" placeholder="Select Value" track-by="name" :clear-on-select="false"
                                                :show-labels="false" label="name" :preselect-first="false">
                                                <a slot="noResult" class="btn btn-primary " v-on:click="openmodel(batch.docType)">Add {{ batch.docType
                                                }}</a><br />

                                            </multiselect>

                </div>
                <div class="form-group has-label">
                    <label class="text  font-weight-bolder"> Submission: </label><br />
                    <div class="form-check form-switch">
                        <input class="form-check-input" style="width: 50px; height: 20px;" v-model="warranty.isSubmitted"
                            type="checkbox">
                    </div>
                </div>
                <div class="form-group has-label col-lg-3 " v-if="warranty.isSubmitted">
                    <label class="text  font-weight-bolder">Supplier: <span class="text-danger"> *</span> </label>

                    <supplierdropdown  v-model="warranty.vendorId" :values="warranty.vendorId"
                        :key="rander"></supplierdropdown>
                </div>
                <div class="form-group has-label col-lg-3" v-if="warranty.isSubmitted">
                    <label class="text  font-weight-bolder" :key="render">Submission Date: </label>

                    <datepicker v-model="warranty.submissionDate" />

                </div>

            </div>

            <div class="row">
                <div class="col-lg-12 mb-5">
                    <div class="table-responsive mt-3" style="overflow-x:visible !important">
                        <table class="table mb-0" style="table-layout:fixed;">

                            <thead class="thead-light">
                                <tr>
                                    <th style="width: 20px;">
                                        #
                                    </th>
                                    <th style="width: 150px;">
                                        Battery Type
                                    </th>

                                    <th class="text-center" style="width: 150px;">
                                        Batch No
                                    </th>
                                    <th class="text-center" style="width: 150px;">
                                        Card Number
                                    </th>

                                    <th style="width: 150px;">
                                        Selling Date
                                    </th>
                                    <th class="text-center" style="width: 150px;">
                                        Vehicle No
                                    </th>
                                    <th class="text-center" style="width: 150px;">
                                        Customer Mobile
                                    </th>

                                    <th class="text-center" style="width: 150px;"
                                        v-if="warranty.isSubmitted && warranty.id != '00000000-0000-0000-0000-000000000000'">
                                        Cash Back Amount
                                    </th>

                                    <th style="width: 30px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item, index) in warranty.warrantyItems">
                                    <tr :key="item.index">
                                        <td>{{ index + 1 }}</td>
                                        <td >
                                            <multiselect v-model="item.battery" v-on:input="onSelectBatteryType(item, item.battery)" :options="batteryType.variantList" :multiple="false"
                                                :value="batch.variantList" placeholder="Select Value" track-by="name" :clear-on-select="false"
                                                :show-labels="false" label="name" :preselect-first="false">
                                                <a slot="noResult" class="btn btn-primary " v-on:click="openmodel(batteryType.docType)">Add {{ batteryType.docType
                                                }}</a><br />

                                            </multiselect>

                                        </td>
                                        
                                        <td class="border-top-0 text-center" >
                                            
                                            <input type="text" v-model="item.batchNo" class="form-control text-center" />

                                        </td>
                                        <td>
                                            <input type="text" v-model="item.cardNo" class="form-control text-center" />
                                        </td>
                                        <td>
                                            <datepicker v-model="item.sellingDate" :key="render" :isDisable="formName == 'CashBack'"/>
                                        </td>

                                        <td>
                                            <input type="text" v-model="item.vehicleNo" class="form-control text-center" />
                                        </td>
                                        <td>
                                            <input type="text" v-model="item.customerMobile" 
                                                class="form-control text-center" />
                                        </td>


                                        <td
                                            v-if="warranty.isSubmitted && warranty.id != '00000000-0000-0000-0000-000000000000' && formName == 'CashBack'">
                                            <decimal-to-fixed v-model="item.cashBackAmount" v-on:input="updateLineRecord(item)"/>
                                        </td>

                                        <td class="text-end">

                                            <a href="javascript:void(0);" @click="removeWarranty(index)"><i
                                                    class="las la-trash-alt text-secondary font-16"></i></a>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>

                    </div>
                    <div class="text-center"  v-if="formName != 'CashBack'">
                        <a v-on:click="addWarranty()" href="javascript:void(0);" class="btn btn-primary mx-1">
                            Add to Warranty card
                        </a>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-12 invoice-btn-fixed-bottom">
                    <div class="button-items">
                        <button type="button" v-if="formName == 'CashBack'" class="btn btn-primary mr-2" v-on:click="saveWarranty()"
                            v-bind:disabled="$v.warranty.$invalid || !warranty.warrantyItems.filter(x => x.cashBackAmount).length > 0">Save</button>
                            <button type="button" v-else class="btn btn-primary mr-2" v-on:click="saveWarranty()"
                            v-bind:disabled="$v.warranty.$invalid || !warranty.warrantyItems.filter(x => x.cardNo).length > 0">Save</button>
                        <button v-on:click="GotoPage('/Warranty')" href="javascript:void(0);"
                            class="btn btn-outline-danger">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            <loading :active.sync="loading" :can-cancel="false" :is-full-page="true">
            </loading>
        </div>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import Loading from 'vue-loading-overlay';
import moment from "moment";

import Multiselect from "vue-multiselect";

import "vue-loading-overlay/dist/vue-loading.css";
import {
    required, requiredIf
} from "vuelidate/lib/validators";
//import VueBarcode from 'vue-barcode';
export default {

    mixins: [clickMixin],
    components: {
        Multiselect,
        Loading
    },
    data: function () {
        return {
            
            sizeOption: [],
            batteryType:'',
            batch:'',
            isBatteryType:false,
            isBatch:false,
            rander: 0,
            render: 0,
            loading: false,
            formName: '',
            warranty: {
                id: '00000000-0000-0000-0000-000000000000',
                date: '',
                submissionDate: '',
                vendorId: '',
                isSubmitted: false,
                totalCashbackAmount: '',
                warrantyItems: [{
                    index: 0,
                    battery: '',
                    batteryType: '',
                    batchNo: '',
                    cardNo: '',
                    sellingDate: '',
                    vehicleNo: '',
                    customerMobile: '',
                    cashBackAmount: 0,
                    isCashback: false,
                    warrantyId: '00000000-0000-0000-0000-000000000000',
                }]
            }
        }
    },
    validations: {
        warranty: {
            warrantyItems: {
                required

            },
            submissionDate: {
                required: requiredIf((x) => {
                    if (x.isSubmitted)
                        return true;
                    return false;
                })
            },
            vendorId: {
                required: requiredIf((x) => {
                    if (x.isSubmitted)
                        return true;
                    return false;
                })
            },
        }
    },
    methods:
    {
        onSelectBatch:function(item){
            this.warranty.warrantyItems[this.warranty.warrantyItems.length - 1].batchNo = item.name
            this.batch.batch = ''
        },
        onSelectBatteryType:function(item, battery){
            item.batteryType = battery.id;
        },
        getSizeRecord: function (isUpdate) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.$https.get('/Product/VarrantyViseRecord?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                debugger;
                if (response.data != null) {
                    
                    root.batteryType = response.data.find(item => item.name.toLowerCase() === 'Battery Type'.toLowerCase());
                    console.log( root.batteryType)
                    root.batch = response.data.find(item => item.name.toLowerCase() === 'Batch'.toLowerCase());
                    if(isUpdate){
                        root.warranty.warrantyItems.forEach(x=>{
                            debugger;
                            x.battery = root.batteryType.variantList.find(y=>y.id == x.batteryType);
                        });
                        debugger;
                        root.render++;
                    }
                }
            });
        },
        addWarranty: function () {
            this.warranty.warrantyItems.push({
                index: this.warranty.warrantyItems.length + 1,
                battery: '',
                batteryType: '',
                batchNo: '',
                cardNo: '',
                sellingDate: '',
                vehicleNo: '',
                customerMobile: '',
                cashBackAmount: 0,
                isCashback: false,
                warrantyId: '00000000-0000-0000-0000-000000000000',
            });

        },
        updateLineRecord: function (item) {
            item.isCashback = true;
            this.warranty.totalCashbackAmount = this.warranty.warrantyItems.reduce((tPrice, tick) => tPrice + parseFloat(tick.cashBackAmount == '' ? 0 : tick.cashBackAmount), 0)
        },
        saveWarranty: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            var isAbleToSave = true;
            this.warranty.warrantyItems.forEach(x => {
                if (x.cardNo == '' || x.cardNo == undefined || x.cardNo == null) {
                    isAbleToSave = false;
                    root.$swal({
                        title: 'Error!',
                        text: 'One of warranty card is empty',
                        type: 'error',
                        confirmButtonClass: "btn btn-danger",
                        icon: 'error',
                        timer: 2500,
                        timerProgressBar: true,
                    });
                }
            })
            if (isAbleToSave) {
                this.$https.post('/Sale/SaveWarranty', this.warranty, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.$swal({
                            title: 'Saved Successfully',
                            text: 'Saved',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true,

                        });
                        root.GotoPage('/Warranty');
                    }
                    else {
                        root.$swal({
                            title: 'Error!',
                            text: 'There is something wrong. Please contact to support.',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire({
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                        root.loading = false
                    })
            }
        },

        removeWarranty: function (index) {
            this.warranty.warrantyItems.splice(index, 1);
        },
        GotoPage: function (link) {
            this.$router.push({
                path: link
            });
        },
        getWarrantyRecordById: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            root.$https.get('Sale/GetWarranty?' + '&Id=' + this.$route.query.id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    debugger;
                    root.warranty.id = response.data.id;
                    root.warranty.vendorId = response.data.vendorId;
                    root.warranty.submissionDate = response.data.submissionDate == null ? '' : moment(response.data.submissionDate).format("LLL");
                    root.warranty.date = moment(response.data.date).format("LLL");
                    root.warranty.isSubmitted = response.data.isSubmitted;
                    root.warranty.totalCashbackAmount = response.data.totalCashbackAmount;
                    root.warranty.warrantyItems = response.data.warrantyItems;
                    root.getSizeRecord(true);
                    root.render++;
                }
                root.loading = false;
            });
        }



    },
    created: function () {
        
        if (this.$route.query.id != null) {
            this.formName = this.$route.query.formName
            this.getWarrantyRecordById();
        }
        else {
            this.warranty.date = moment().format("LLL");
            this.getSizeRecord(false);
        }

    },
    mounted: function () {


    },
};
</script>
<style scoped>
.ticket-details {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.ticket-details>div {
    flex: 0 0 calc(100% / 7);
    /* Divide into 7 equal columns */
    padding: 0 5px;
    /* Adjust spacing between columns */
    box-sizing: border-box;
    margin-bottom: 10px;
}

/* Adjust responsiveness for smaller screens */
@media (max-width: 768px) {
    .ticket-details>div {
        flex: 0 0 calc(100% / 2);
        /* For smaller screens, display 2 columns per row */
    }
}</style>